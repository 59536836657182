export const col = [
  {
    field: "date",
    use: "Date",
    //Will not be used in search filtering
    //  use_in_search:false
  },
  {
    field: "sent",
    use: "Sent",
  },

  {
    field: "received",
    use: "Received",
  },
  {
    field: "txlink",
    use: "Tx Link",

  }
]

export const row = [
  {
    id: 1,
    date:"2021-01-01",
    name: "Miracle Nwabueze",
    country_id: 3,
    club_id: 2,
    front_end_position: {
      name: {
        full_name: "Forward",
        short_code: "FW"
      },
      id: 2
    }
  },
  
];


export const full_table_styling = {
  base_bg_color: "bg-green-600 base_bg_color_class",
  base_text_color: "text-green-600 base_text_color_class",
  top: {
    title: "text-red-700 top_title_class",
    elements: {
      main: "bg-green-700 top_elements_main_class",
      search: "text-white top_elements_search_class",
      bulk_select: {
        main: "bg-green-700 text-white top_elements_bulk_search_main_class",
        button: "bg-yellow-700 text-black px-5 top_elements_bulk_search_button_class"
      },
      export: "text-yellow-800 top_elements_bulk_search_main_class top_elements_export_class"
    }
  },
  table_head: {
    table_row: "bg-green-800 text-white",
    table_data: "text-white"
  },
  table_body: {
    main: "bg-red-600",
    table_row: "text-yellow-900",
    table_data: "text-base"
  },
  footer: {
    main: "bg-yellow-700",
    statistics: {
      main: "bg-white text-green-900",
      bold_numbers: "text-yellow-800 font-thin"
    },
    page_numbers: "bg-red-600 text-white"
  }
}