import {getTimeLine} from "../../utils/handlers";
import { useState } from "react";

export default function DropDown(props) {
    console.log("Dropdown Props", props);
    const [timeline, settimeline] = useState(getTimeLine());

    function changeTimeline(param){
        localStorage.setItem("timeline", param)
        settimeline(param);
        props.changeTimeline(param);

    }



    return (
        <>
            <button id="dropdownLargeButton" data-dropdown-toggle="dropdownLarge"
             className="inline-flex items-center py-3 px-5 mb-3 text-base font-medium text-center text-black rounded-lg md:mb-0  focus:ring-4 focus:ring-black-300 " 
             type="button">{timeline+" Month"}
             
             <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
             
             </button>

            <div id="dropdownLarge" className="hidden z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">

                <ul className="py-1" aria-labelledby="dropdownLargeButton">
                    <li>
                        <a className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" 
                        onClick={()=>{changeTimeline("1")}}>1 Month</a>
                    </li>
                    <li>
                        <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" 
                        
                        onClick={()=>{changeTimeline("3")}}>3 Months</a>
                    </li>
                    <li>
                        <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" 
                        
                        onClick={()=>{changeTimeline("6")}}>6 Months</a>
                    </li>
                </ul>

            </div>
        </>

    )
}