import React, { useEffect } from 'react';
import logo from "../../images/logo2000x2000.png"
// Import utilities
import { humanFriendlyBalance, getCurrency } from '../../utils/handlers';
import { tailwindConfig, hexToRGB } from '../../utils/Utils';


function Deposits(props) {
  const [total, setTotal] = React.useState(0);
  const [currency, setCurrency] = React.useState(getCurrency());






  useEffect(() => {
    console.log(props)
    //Sum the list of values
    let total = 0;

    if (props.data) {
      console.log(props.data.received)
      total = props.data.received.reduce((acc, item) => acc + parseFloat(humanFriendlyBalance(parseInt(item.total_sent))), 0);
    }

    get_deposits(props.address).then(res => {
      //console.log(res.deposits)
      // setTotal(res.total_received.concat(res.total_sent));
      setTotal(res.deposits);
      //setTotal(45000);
      

    })
  }, [props]);

  const [dates, setDates] = React.useState([]);
  const [received, setReceived] = React.useState([]);
  const [sent, setSent] = React.useState([]);
  const [deposit, setDeposit] = React.useState(0);
  const [CurrencyTotal, setCurrencyTotal] = React.useState(0);

  async function get_deposits(address) {
    let data;
    try {
      let resp = await fetch('https://api.oneyield.co.uk/get_deposits', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "address": address
        })
      });
      data = await resp.json();
      console.log("Deposirts", data)
    }
    catch {
      data = { "deposits": 0 }
    }

    return data;
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.data) {
      let dates = (props.data.received.map(item => item.date));
      let receivedx = (props.data.received.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_received)))));
      let sentx = (props.data.received.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_sent)))));
      setDates(dates);
      setReceived(receivedx);
      setSent(sentx);


    }
  }, [props.data]);


  const chartData = {
    labels: dates,
    datasets: [
      // Indigo line
      {
        data: sent,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },

    ],
  };

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('CURRENCY')
  
      if (item) {
      
        setCurrency(item);
      }
    }
  
    window.addEventListener('storage', checkUserData)
  
    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [])


  async function convertCurrency(){
    let selectedCurrency = localStorage.getItem('CURRENCY');
    
    if(typeof selectedCurrency !== 'string'){
     selectedCurrency = "GBP"
    }
    let url = `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/gbp/${selectedCurrency.toLowerCase()}.json`
    let resp = await fetch(url);
    let data = await resp.json();
    //delete key from object
    delete data.date;
    //get first item from object
    let rate = Object.values(data)[0];
    console.log("new Total", total * rate)
    setCurrencyTotal(total * rate);

    

  }

  useEffect(() => {
    convertCurrency();
  }, [total,currency])

  let SYMBOLS={
    "USD": "$",
    "EUR": "€",
    "BTC": "₿",
    "GBP":"£"
  }

  

  return (
    <div className="flex  flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">

      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          <img src={logo} width="32" height="32" alt="Icon 01" />
          {/* Menu button */}

        </header>
        <h2 className="text-lg font-semibold text-slate-800 mb-2 ">Total Value</h2>
        {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
        <div className="flex items-start mb-10">
          <div className="text-3xl font-bold text-slate-800 mr-2 ">{SYMBOLS[currency]} {CurrencyTotal.toFixed(3)}</div>


          <button className='text-sm font-semibold text-white px-1.5 bg-yellow-500 '

            onClick={() => {
              window["toggleModal"]('currency-modal');
            }}

          >Change Currency
          </button>

          {/* <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">+49%</div> */}
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {/* <LineChart data={chartData} width={389} height={128} /> */}
      </div>
    </div>
  );
}

export default Deposits;
