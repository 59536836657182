import React, { useEffect } from 'react';
import MonthlyBar from '../../charts/MonthlyBar';
import { humanFriendlyBalance } from '../../utils/handlers';
import MonthlyChart from '../../charts/MonthlyChart';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';


function WeeklyProfitsV2(props) {
    const [dates, setDates] = React.useState([]);
    const [received, setReceived] = React.useState([]);
    const [sent, setSent] = React.useState([]);
    const [profits, setProfits] = React.useState([]);
    const [months, setMonths] = React.useState([]);
    const [monthlyProfits, setMonthlyProfits] = React.useState([]);
    


    useEffect(() => {
        if (props.data) {
            let dates = (props.data.map(item => item.date));
            let receivedx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_received)))));
            let sentx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_sent)))));
            setDates(dates);
            setReceived(receivedx);
            setSent(sentx);
        }
    }, [props.data]);

    useEffect(() => {
        fetch("https://api.oneyield.co.uk/get_weekly_profits").then(res => res.json()).then(data => {

            setProfits(data.profits);
            let months = [];
            let monthlyProfits = [];
            data.profits.forEach(item => {
                const MonthMap = {
                    "january": 1,
                    "february": 2,
                    "march": 3,
                    "april": 4,
                    "may": 5,
                    "june": 6,
                    "july": 7,
                    "august": 8,
                    "september": 9,
                    "october": 10,
                    "november": 11,
                    "december": 12
                };
                let currentYear = new Date().getFullYear();
                let month = MonthMap[item.Month.toLowerCase()];
                let dateParsed = `${currentYear}-${month}`;
                months.push(dateParsed);
                let allProfitPercent =  item.Week1+item.Week2+item.Week3+item.Week4;
                monthlyProfits.push(allProfitPercent);
            });
            setMonths(months);
            setMonthlyProfits(monthlyProfits);
            console.log(months,monthlyProfits);
            
        });
        
    }, []);

    
    



    const chartData = {
        labels: months,
        datasets: [

            {
                label: 'Profits',
                data: monthlyProfits,
                backgroundColor: tailwindConfig().theme.colors.blue[400],
                hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
                barPercentage: 0.66,
                categoryPercentage: 0.66,
            },

           
        ],
    };


    return (
        <div className="flex flex-col col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
            <header className="px-5 py-4 border-b border-slate-100">
                <h2 className="font-semibold text-slate-800">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up-arrow inline mr-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                        </svg>
                        Weekly Estimated Profit
                </h2>
            </header>

            {/* //Row with 3 column tailwind */}
            {profits[0] &&
                <div className="grid grid-cols-1 lg:grid-cols-11">

                    <div className="flex  flex-col  xl:col-span-2 bg-white shadow-lg rounded-sm border border-slate-200">

                        <div className="px-5 pt-5">
                            
                            <h2 className="text-lg font-semibold text-slate-800 mb-2 ">{profits[profits.length -1].Month.toUpperCase()} (Week 1)</h2>
                            {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
                            <div className="flex items-start mb-10">
                                <div className="text-3xl font-bold text-green-800 mr-2 ">{profits[profits.length -1].Week1}%</div>

                        
                            
                            </div>

                        </div>
                    </div>
                    <div className="flex  flex-col  xl:col-span-3 bg-white shadow-lg rounded-sm border border-slate-200">

                        <div className="px-5 pt-5">
                            
                            <h2 className="text-lg font-semibold text-slate-800 mb-2 ">{profits[profits.length -1].Month.toUpperCase()} (Week 2)</h2>
                            {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
                            <div className="flex items-start mb-10">
                                <div className="text-3xl font-bold text-green-800 mr-2 ">{profits[profits.length -1].Week2}%</div>

                        
                            
                            </div>

                        </div>
                    </div>
                    <div className="flex  flex-col  xl:col-span-3 bg-white shadow-lg rounded-sm border border-slate-200">

                        <div className="px-5 pt-5">
                            
                            <h2 className="text-lg font-semibold text-slate-800 mb-2 ">{profits[profits.length -1].Month.toUpperCase()} (Week 3)</h2>
                            {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
                            <div className="flex items-start mb-10">
                                <div className="text-3xl font-bold text-green-800 mr-2 ">{profits[profits.length -1].Week3}%</div>

                        
                            
                            </div>

                        </div>
                    </div>
                    <div className="flex  flex-col  col-span-full xl:col-span-3 bg-white shadow-lg rounded-sm border border-slate-200">

                        <div className="px-5 pt-5">
                            
                            <h2 className="text-lg font-semibold text-slate-800 mb-2 ">{profits[profits.length -1].Month.toUpperCase()} (Week 4)</h2>
                            {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
                            <div className="flex items-start mb-10">
                                <div className="text-3xl font-bold text-green-800 mr-2 ">{profits[profits.length -1].Week4}%</div>

                        
                            
                            </div>

                        </div>
                    </div>
                
                    
                </div>
            }
            <div className="grow">
            
            {/* Change the height attribute to adjust the chart height */}
            {/* <MonthlyChart data={chartData} width={389} height={300} /> */}
            <MonthlyBar data={chartData} width={595} height={248} />
            </div>
        </div>
    );
}

export default WeeklyProfitsV2;
