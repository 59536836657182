import React, { useState, useEffect } from 'react';
import UserMenu from './header/UserMenu';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import { ToastContainer, toast } from 'react-toastify';
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from "../images/logo2000x2000.png"
import 'react-toastify/dist/ReactToastify.css';
function Header(props) {

  localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
  const [userData, setUserData] = useState({});
  const [isConnected, setIsConnected] = useState(false);


  async function Connect(modalOption) {
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    localStorage.removeItem("walletconnect");
    let providerOptions;
    if(modalOption ===   "metamask"){
      providerOptions = {}
    }else if(modalOption === "walletconnect"){
      providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
               56: 'https://bsc-dataseed.binance.org/'
            },
            network: 'binance',
          }
        }
      };
    }

    let disableInjected = true;
    if(modalOption === "metamask"){
      disableInjected = false;
    }


    const web3Modal = new Web3Modal({
      network: "binance", // optional
      cacheProvider: false, // optional
      disableInjectedProvider: disableInjected, // optional
      providerOptions // required
    });


    const provider = await web3Modal.connect();
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts: string[]) => {
      window.location.reload();
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId: number) => {
      window.location.reload();
    });

    // Subscribe to provider connection
    provider.on("connect", (info: { chainId: number }) => {
      window.location.reload();
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error: { code: number; message: string }) => {
      window.location.reload();
    });
    const web3 = new Web3(provider);
    const chainId = await web3.eth.getChainId();
    if (chainId !== 56) {
      toast.error("Switch your network to BSC;");
      return;
    }
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    //const account = "0x262B955edc277d44B1cEFC1d3C1c2fb3ED2Ef08E"
    const balance = await web3.eth.getBalance(account);
    const balanceInEther = web3.utils.fromWei(balance, "ether");
    setIsConnected(true);
    const toUser = {
      "account": account,
      "balance": balanceInEther,
      "connect": Connect,
      "isConnected": true,
      "web3Instance": web3,
    }
    props.update(toUser);
    setUserData(toUser);
    console.log("Cojnnected");


  }

  useEffect( () => {

    setUserData({ connect: Connect, "isConnected": isConnected })

    props.update({ connect: Connect, "isConnected": isConnected });
  }, []);


  // useEffect(() => {
  //   Connect();
  // }, []);

  return (
    <>
     <ToastContainer position="top-center" />
     
     
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
     
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

        <img src={logo} width="42" height="42" alt="Icon 01" />
          <div className="flex">

          


          </div>


          <div className="flex items-center">




            <UserMenu userData={userData} />

          </div>

        </div>
      </div>
    </header>
    </>
  );
}

export default Header;