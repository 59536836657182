import React, { useEffect } from 'react';

import './css/style.scss';

import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/NewDashboard';
import 'flowbite';

function App() {

  

  

  return (
    <Dashboard />
  );
}

export default App;
