import React,{useEffect} from 'react';
function Stats({web3Instance,userData}) {

    const [grossCollection, setGrossCollection] = React.useState(0);
    const [depositedAmount, setDepositedAmount] = React.useState(0);

    

    useEffect( () => {
        async function getGrossCollection() {
        const total = await web3Instance.eth.getBalance("0xdd18Dd351f28beFd4e9418447daCe5E17F8e2Ed9");
        //Convert to ether
        const totalInEther = parseFloat(web3Instance.utils.fromWei(total, 'ether'));
        
        setGrossCollection(totalInEther.toFixed(2));
        }
        getGrossCollection();
    }, []);
    
    useEffect( () => {
        
        async function getDepositedAmount() {
            let total = 0;
            let data = await fetch("https://api.oneyield.co.uk/deposits/")
            let json = await data.json();
            for (let i = 0; i < json.length; i++) {
                if (json[i].to_address === "0xdd18dd351f28befd4e9418447dace5e17f8e2ed9" && json[i].from_address === (userData.account).toLowerCase()) {
                    let value = json[i].value;
                    total += parseFloat(web3Instance.utils.fromWei(value, 'ether'));
                        //s
                }
            }
            setDepositedAmount(total.toFixed(2));
        }
        if(userData && typeof userData.account !== "undefined"){
           
            getDepositedAmount();
        }
    }, [userData,web3Instance]);



    return (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
            <header className="px-5 py-4 border-b border-slate-100">
                <h2 className="font-semibold text-slate-800">Total contribution Stats</h2>
            </header>
            <div className="px-5 py-3">
                <div className="flex items-start">
                    <div className="text-3xl font-bold text-slate-800 mr-2">{grossCollection}/1500</div>
                    <div className="text-sm font-semibold text-white px-1.5 bg-yellow-500 rounded-full">{((grossCollection/1500)*100).toFixed(2)}%</div>
                   
                </div>
                <h2>Your Contribution : {depositedAmount} BNB</h2>
            </div>
            {/* Chart built with Chart.js 3 */}
            <div className="grow mb-10 p-2">
                {/* Change the height attribute to adjust the chart height */}
                <div className="w-full bg-gray-200 rounded-full">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width:((grossCollection/1500)*100).toFixed(2)+"%"}}> {((grossCollection/1500)*100).toFixed(2)}%</div>
                </div>
            </div>
        </div>
    );
}

export default Stats;
