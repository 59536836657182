import React, { useEffect } from 'react';
import { humanFriendlyBalance, calculateProfitPercentage } from '../../utils/handlers';
function DashboardCard07(props) {

  const [totalInfo, setTotalInfo] = React.useState({});
  useEffect(() => {
    if (props.overviewData.received) {
      let total_sent =0;
      let total_received =0;

      props.overviewData.received.map(item => {
        
        total_received = total_received + item.total_received;
        total_sent = total_sent + item.total_sent;
      });
     
      setTotalInfo({
        totalSent: humanFriendlyBalance(total_sent),
        totalReceived: humanFriendlyBalance(total_received),
      });
    }




  }, [props]);
  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Detailed Overview</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm ">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Investment</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Profit</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Profit (%) </div>
                </th>

              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100">
              {/* Row */}

              {/* Row */}
              {props.overviewData.received && props.overviewData.received.map((item, index) => (
                <tr key={index}>
                  <td className="p-2">
                    <div className="flex items-center">
                      <div className="text-slate-800">{item.date.split("-").slice(0,2).join("-")}</div>
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">{humanFriendlyBalance(item.total_sent)}</div>
                  </td>
                  <td className="p-2">
                    <div className="text-center text-green-500">{humanFriendlyBalance(item.total_received)}</div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">{(calculateProfitPercentage(item.total_sent, item.total_received))}</div>
                  </td>

                </tr>
              ))}



            </tbody>
            <tfoot >
              <tr>
                <td className="p-2">
                  <div className="flex items-center">
                    <div className="text-slate-800">Total</div>
                  </div>
                </td>

                <td className="p-2">
                  <div className="text-center font-bold	">{totalInfo.totalSent}</div>
                </td>
                <td className="p-2">
                  <div className="text-center text-green-500 font-bold	">{totalInfo.totalReceived}</div>
                </td>
                <td className="p-2">
                  <div className="text-center font-bold	">{(calculateProfitPercentage(totalInfo.totalSent, totalInfo.totalReceived))}</div>
                </td>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
    </div>
  );
}

export default DashboardCard07;



