
import logo from "../../../images/Tlogo.png"
export default function About() {

  return (

    <section className="relative pb-20">


      <div className="relative container pt-12 px-4 mx-auto text-center">
        <span className="text-blue-400 font-semibold">ABOUT US</span>
        <h2 className="mt-8 mb-8 lg:mb-12 text-4xl lg:text-6xl font-semibold">Residential Development
        </h2>
        <p className="max-w-3xl mx-auto mb-8 lg:mb-12 text-xl text-gray-500">OneYield is proud to bring our investors the chance to pledge towards a 50-60 Unit residential development in the north of Manchester.
          Our client is looking to secure a £400,000 - £500,000 pledge and in return is willing to offer a 200%-400% return.

        </p>

      </div>
      <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-lg font-semibold leading-none" href="#">
              <img className="h-7" src="zeus-assets/logo/logo-zeus-red.svg" alt="" width="auto" />
            </a>
            <button className="navbar-close">
              <svg className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">About</a></li>
              <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Company</a></li>
              <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Services</a></li>
              <li className="mb-1"><a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="#">Testimonials</a></li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6"><a className="block py-3 text-center text-sm leading-normal bg-red-50 hover:bg-red-100 text-red-300 font-semibold rounded transition duration-200" href="#">Contact Us</a></div>
            <p className="mt-6 mb-4 text-sm text-center text-gray-500">
              <span>© 2021 All rights reserved.</span>
            </p>
          </div>
        </nav>
      </div>
    </section>


  )

}