import React, { useState, useEffect } from 'react';
import Header from '../partials/Header';
import WalletModal from '../partials/walletmodal';
import DepositModal from '../partials/DepositModal';
import TAB1 from './Tab1';
import TAB2 from './Tab2';
import CurrencyModal from "../partials/Modals/Currency";
function Dashboard() {

    

    const [userData, setUserData] = useState({});
    const [tab, setTab] = useState(0);



    useEffect(() => {

        window["toggleModal"]('walletModal');

    }, []);

    useEffect(() => {
        if (userData.account) {
            window["toggleModal"]('walletModal', false);
        }
    }, [userData]);
    




    return (

        <div className="flex h-screen overflow-hidden">
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header update={setUserData} />
                <WalletModal update={userData} />
                <DepositModal userData={userData} />
                <CurrencyModal />
                <main>
                <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    <li className="mr-2">
                        <a href="#" aria-current="page" className={"inline-block p-4 rounded-t-lg" + (tab === 0 ? " active text-blue-600  bg-gray-100 " : "")} 
                        onClick={() => setTab(0)}>
                        
                            Dashboard</a>
                    </li>
                    <li className="mr-2">
                        <a href="#" className={"inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 " + (tab === 1 ? "active text-blue-600  bg-gray-100 " : "")}
                        onClick={() => setTab(1)}
                        >Project ONE</a>
                    </li>
                    
                </ul>
                {tab === 0 && <TAB1 userData={userData}/>}
                {tab === 1 && <TAB2 userData={userData}/>}

                    {/* <TAB1 userData={userData}/> */}
                </main>



            </div>
        </div>
    );
}

export default Dashboard;