import React,{useEffect} from 'react';
import LineChart from '../../charts/LineChart01';
import BNB from "../../images/bnb.png";
// Import utilities
import { humanFriendlyBalance } from '../../utils/handlers';
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function DashboardCard01(props) {
  const [total, setTotal] = React.useState(0);
  useEffect(() => {
    console.log(props)
    //Sum the list of values
    let total = 0;

    if(props.data){
      total = props.data.reduce((acc, item) => acc + parseFloat(humanFriendlyBalance(parseInt(item.total_sent))), 0);
    }
    setTotal(total);
  }, [props]);

  const [dates, setDates] = React.useState([]);
  const [received, setReceived] = React.useState([]);
  const [sent, setSent] = React.useState([]);


  useEffect(() => {
    if(props.data){
      let dates = (props.data.map(item => item.date));
      let receivedx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_received)))));
      let sentx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_sent)))));
      setDates(dates);
      setReceived(receivedx);
      setSent(sentx);
    }
  }, [props.data]);


  const chartData = {
    labels: dates,
    datasets: [
      // Indigo line
      {
        data: sent,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
      
    ],
  };

  return (
    <div className="flex  flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
      
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          <img src={BNB} width="32" height="32" alt="Icon 01" />
          {/* Menu button */}
          
        </header>
        <h2 className="text-lg font-semibold text-slate-800 mb-2 ">Total Sent </h2>
        {/* <div className="text-xs font-semibold text-slate-400 uppercase mb-1">Sales</div> */}
        <div className="flex items-start mb-10">
          <div className="text-3xl font-bold text-slate-800 mr-2 ">{total.toFixed(3)} BNB</div>
          
          {/* <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">+49%</div> */}
          <button className='text-sm font-semibold text-white px-1.5 bg-yellow-500 '

          onClick={()=>{
            window["toggleModal"]('deposit-modal');
          }}
          
          >Deposit
          </button>
          
        </div>
        
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData} width={389} height={128} />
      </div>
      
    </div>
  );
}

export default DashboardCard01;
