import React,{useEffect} from 'react';
import BarChart from '../../charts/BarChart01';
import { humanFriendlyBalance } from '../../utils/handlers';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function DashboardCard04(props) {
  const [dates, setDates] = React.useState([]);
  const [received, setReceived] = React.useState([]);
  const [sent, setSent] = React.useState([]);


  useEffect(() => {
    if(props.data){
      let dates = (props.data.map(item => item.date));
      let receivedx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_received)))));
      let sentx = (props.data.map(item => parseFloat(humanFriendlyBalance(parseInt(item.total_sent)))));
      setDates(dates);
      setReceived(receivedx);
      setSent(sentx);
    }
  }, [props.data]);

  const chartData = {
    labels: dates,
    datasets: [
      
      {
        label: 'Profits',
        data: received,
        backgroundColor: tailwindConfig().theme.colors.blue[400],
        hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
     
      {
        label: 'Investment',
        data: sent,
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };
  

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Profits vs Investment</h2>
      </header>
     
      <BarChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default DashboardCard04;
