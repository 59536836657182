import {useState,useEffect} from 'react';

export default function Updates() {

    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        fetch('https://api.oneyield.co.uk/updates/')
        .then(res => res.json())
        .then(data => {
            setUpdates(data);
            console.log("updates", data);
        })
    }, []);

    function parseTitleAndDescription(content){
        let title = content.split("\n")[0];
        let description = content.split("\n")[1];
        return {title, description};
    }


    return (
        <section className="relative pb-20">


            <div className="relative container pt-12 px-4 ">
                <h1 className="mb-10 text-3xl font-semibold">Updates</h1>
                <ol className="relative border-l border-gray-200 ">
                    
                    {updates.map((update,index )=> (

                        <li className="mb-10 ml-6">
                            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white ">
                                <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">{parseTitleAndDescription(update.content).title} 
                            {index == 0 &&
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">Latest</span>
                            }
                            </h3>
                            
                            <time className="block mb-2 text-sm font-normal leading-none text-gray-400">{update.timestamp}</time>
                            <p className="mb-4 text-base font-normal text-black-500 text-black-500">{parseTitleAndDescription(update.content).description}</p>
                            
                        </li>
                    ))}
                    
                </ol>
            </div>
        </section>

    )
}