import React from 'react';
import { useState, useEffect } from 'react';
import Table, { Irow } from 'react-tailwind-table'
import { col, row } from "../../utils/Table";
import { getTimeLine, humanFriendlyBalance } from '../../utils/handlers';
import 'react-tailwind-table/dist/index.css'

export default function Transactions(props) {
  console.log("Date changed in Transaction as well")

  const [rows, setRows] = useState([]);

  function fallsundertimeline(date) {
    let month = getTimeLine();
    let date1 = new Date(date);
    //date2 is today minus one month
    let date2 = new Date(new Date().setMonth(new Date().getMonth() - month));

    //Check if the date is greater than date2
    if (date1 > date2) {
      return true;
    }
    return false;



  }


  function returnFormattedData(rawData) {
    let formattedData = [];
    for (let i = 0; i < rawData.length; i++) {
      let to_push = {}
      to_push.id = i;
      to_push.name = "SlothMinded"
      to_push.date = rawData[i].block_signed_at;
      to_push.txlink = "https://bscscan.com/tx/" + rawData[i].tx_hash;
      console.log(rawData[i].block_signed_at,fallsundertimeline(rawData[i].block_signed_at))
      if (rawData[i].from_address.toLowerCase() == props.account.toLowerCase()) {
        to_push.sent = humanFriendlyBalance(rawData[i].value);
        to_push.received = "0";
      }
      else {
        to_push.sent = "0";
        to_push.received = humanFriendlyBalance(rawData[i].value);
      }
      if (fallsundertimeline(rawData[i].block_signed_at)) {
        formattedData.push(to_push);
      }
    }
    return formattedData;
  }
  useEffect(() => {
    if (props && props.transactions) {
      setRows(returnFormattedData(props.transactions));

    }
  }, [props.transactions,props.date])



  return (

    <>
      <div className="col-span-full xl:col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
        <header className="px-5 py-4 border-b border-slate-100">
          <h2 className="font-semibold text-slate-800">Transactions</h2>
        </header>
        <div className="p-3">

          {/* Table */}
          <div className="overflow-x-auto">

            {rows.length > 0 &&
              <Table columns={col} rows={rows}
                per_page={10}


                show_search={false}

                striped={true}
                bordered={true}
                hovered={true}
                checked_set={false}
              ></Table>

            }

          </div>

        </div>

      </div>

    </>

  );
}


