




function getDates() {
  let start_date = localStorage.getItem('start_date');
  let end_date = localStorage.getItem('end_date');
  if (start_date && end_date) {
    return { start_date, end_date };
  }
  else {

    //Start date = 1 month ago of today %d %m %Y
    let start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);
    start_date = start_date.getDate() + '-' + (start_date.getMonth() + 1) + '-' + start_date.getFullYear();
    //End date = today %d %m %Y
    let end_date = new Date();
    end_date = end_date.getDate() + '-' + (end_date.getMonth() + 1) + '-' + end_date.getFullYear();
    localStorage.setItem('start_date', start_date);
    localStorage.setItem('end_date', end_date);
    return { start_date, end_date };
  }
}

function getTimeLine() {
  let month = localStorage.getItem("timeline");
  if (month) {
    return month;
  }
  else {
    localStorage.setItem("timeline", "1");
    return "1";
  }
}

function humanFriendlyBalance(balance) {
  //Convert wei to eth without using web3
  if (balance == 0) {
    return 0;
  }
  return (parseFloat(balance) / 1000000000000000000).toFixed(4);
}

function calculateProfitPercentage(investment, received) {
  if (investment == 0) {
    return 0;
  }
  let profit = received;
  let percentage = (profit / parseFloat(investment)) * 100;
  return percentage.toPrecision(4);
}

function getCurrency() {
  return localStorage.getItem("CURRENCY");
}


function setCurrency(currency) {
  
  localStorage.setItem("CURRENCY", currency);
  window.dispatchEvent(new Event("storage"));
  return currency
  
}

export { getTimeLine, getDates, humanFriendlyBalance, calculateProfitPercentage, getCurrency ,setCurrency};
