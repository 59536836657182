import React from 'react';
import About from '../partials/tab2components/About';
// import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import Stats from '../partials/tab2components/Stats';
import Deposit from '../partials/tab2components/Deposit';
import Updates from '../partials/tab2components/Updates';
function TAB2(props) {

    console.log("TAB2 props", props);


    return (


        <section id="tab2">

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                <About />
                <div className="grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 grid-gap-2">
                    <div>
                        <Stats web3Instance={props.userData.web3Instance} userData={props.userData}/>
                    </div>
                    <div>
                        <Deposit userData={props.userData}/>
                    </div>

                </div>
                <Updates/>
            </div>
        </section>




    );
}

export default TAB2;