import React, { useState, useEffect } from 'react';


import Header from '../partials/Header';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import Deposits from '../partials/dashboard/Deposits';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import Transactions from '../partials/dashboard/Transaction';
import WalletModal from '../partials/walletmodal';
import { getDates, getTimeLine } from '../utils/handlers';
import DropDown from '../partials/dashboard/DropDown';
import DepositModal from '../partials/DepositModal';
import WeeklyProfitsV2 from '../partials/dashboard/WeeklyProfitsv2';


function TAB1(props) {

    const [txRecords, settxRecords] = useState({});
    const [userData, setUserData] = useState({});
    const [rawTransactions, setRawTransactions] = useState([]);
    const [furnishedData, setFurnishedData] = useState({});
    const [detailedOverview, setDetailedOverview] = useState({});
    const [filteredByTimeline, setFilteredByTimeline] = useState([]);
    const [timeline, setTimeline] = useState(getTimeLine());
    let baseAPI = "https://api.oneyield.co.uk";


    useEffect(() => {

        setUserData(props.userData);

    }, [props.userData]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        console.log(userData);

        if (userData.account) {

            // window["toggleModal"]('walletModal', false);

            let data = await fetch(`${baseAPI}/get_txs/`, {
                method: 'POST', headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "address": userData.account
                })
            });
            let res = await data.json();
            console.log("RECHE")
            setRawTransactions(res.total_received.concat(res.total_sent));
            let furnishedres = await fetch(`${baseAPI}/get_polished_data/`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "address": userData.account
                })
            });
            let furnisheddata = await furnishedres.json();
            setFurnishedData(furnisheddata);
            console.log("Furnished Data",furnisheddata);
        }
    }, [userData]);

    async function get_detailed_overview() {
        let overview = await fetch(`${baseAPI}/get_detailed_overview/`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "address": userData.account,
            })
        });
        let overviewdata = await overview.json();
        return overviewdata;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        let overview = await get_detailed_overview();
        setDetailedOverview(overview);
    }, [timeline]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (userData.account) {
            let overviewdata = await get_detailed_overview();
            setDetailedOverview(overviewdata);

        }
    }, [userData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (detailedOverview.received) {
            //Slice list by timeline
            let timeline = getTimeLine();
            let slicedList = detailedOverview.received.slice(detailedOverview.received.length - timeline, detailedOverview.received.length);
            setFilteredByTimeline(slicedList);

        }
    }, [detailedOverview]);


    return (


        <section id="tab1">

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                        <DropDown changeTimeline={setTimeline} />


                    </div>

                </div>
                <div className="grid grid-cols-12 gap-6">


                    {furnishedData.sent_dates &&
                        <>

                            <DashboardCard01 data={filteredByTimeline} web3Instance={userData.web3Instance} />

                            <DashboardCard02 data={filteredByTimeline} />
                            <Deposits data={detailedOverview} address={userData.account} />
                            <WeeklyProfitsV2 data={filteredByTimeline} />

                        </>

                    }
                    {/* <WeeklyProfits/> */}


                    <DashboardCard04 data={filteredByTimeline} />

                    <DashboardCard07 overviewData={detailedOverview} />

                    <Transactions transactions={rawTransactions} date={timeline} account={userData.account} />
                </div>

            </div>
        </section>




    );
}

export default TAB1;