import React, { useEffect, useState } from "react";
import { getCurrency,setCurrency } from "../../../utils/handlers";
export default function CurrencyModal() {

    const [currency1, setCurrency1] = useState(getCurrency());

    return (
        <>
            <div id="currency-modal" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center h-modal md:h-full md:inset-0">
                <div className="relative px-4 w-full max-w-md h-full md:h-auto">

                    <div className="relative bg-white rounded-lg shadow ">

                        <div className="flex justify-between items-center py-4 px-6 rounded-t border-b ">
                            <h3 className="text-base font-semibold text-gray-900 lg:text-xl ">

                                Change Currency
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="currency-modal"

                            onClick={()=>{
                                window["toggleModal"]('currency-modal',false);
                            }}

                            >
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>

                        </div>

                        <div className="p-6">
                            <p className="text-sm font-normal text-gray-500 ">The Currency is Reflected Under Total Value</p>

                            <div>

                                <div class="mb-6">
                                    <label for="amount" class="block mb-2 text-sm font-medium text-gray-900 ">Currency</label>
                                    <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    
                                onChange={(e) => {
                                    console.log("Currency Changeds",e.target.value)
                                    setCurrency(e.target.value);

                                }}
                                    
                                    
                                    >
                                        <option value="USD">US$</option>
                                        <option value="EUR">EUR€</option>
                                        <option value="BTC">BTC₿ </option>
                                        <option value="GBP">GBP£</option>
                                    </select>
                                
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}